<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        HR/C&B/Company News
      </h2>
      <img
        src="../../assets/img/tools_news_banner.jpg"
        alt="Forecasr Predictor Banner"
        class="w-full lg:h-96 h-80 mt-4"
      >
      <br>
      <br>
      <div>
        Get access to the breaking HR, C&B and company related news as
        we consolidates and classifies the latest news for you.
      </div>
        <br>
        <img
          src="../../assets/img/tools_news.png"
          alt="Forecast Predictor Banner"
          class="w-full mx-auto"
        >
        <br>
        <div>
          Worldwide HR/C&B/Company News by country:
          <ul class="px-10 p-4 list-disc text-red-700">
            <li>COVID-19</li>
            <li>Hiring</li>
            <li>Pay</li>
            <li>Redundancy</li>
            <li>Union</li>
            <li>M&A</li>
            <li>etc.</li>
          </ul>
        </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
